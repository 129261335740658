import React from 'react'
import {setShowMenu} from '../actions'; 
import Theme from '../components/theme/Theme';
import { connect } from 'react-redux'; 
import Header from '../components/header/Header';
import Title from '../components/title/Title';
import NameLogo from '../components/nav/NameLogo';
import Button from '../components/button/Button';
import SignUp from '../modals/SignUp'; 
import { setShowModal,setModalContent } from '../actions'; 
import BottomBar from '../components/nav/BottomBar';
import DropDownBox from '../components/DropDownBox/DropDownBox';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Iphone from '../assets/iphone.png'
import thirdImage from '../assets/image3.avif'
import secondImage from '../assets/image2.avif'
const Home = (props) => {
  const theme = Theme();

  const signupFunc = (isProvider)=>{props?.setModalContent(<SignUp isProvider={isProvider}/>);props?.setShowModal(true)}
  return (
    <div style={{width:'100%',backgroundColor:'white',overflowX:"hidden"   ,display:'flex',flexDirection:'column'}}> 
        <div style={{width:'100vw',display:'flex',flexDirection:'column',position:'relative',minHeight:'50vw',overflow:'hidden',flexWrap:'wrap-reverse',margin:'auto'}}> 
            <div style={{ display:'flex',flexFlow:'column',position:'absolute',justifyContent:'center',minHeight:'50vw',top:0,left:0,bottom:0,right:0,backgroundColor:'rgba(255,255,255,0.40)',zIndex:'1' }}> 
                    <Header data="Payments made easy."  style={{margin:'auto',zIndex:'10',width:'90%'   ,padding:'0px',fontWeight:'900',marginBottom:'0vw',textShadow:'0px 0px 50px white',marginTop:theme?.isPastBreak?'45px':'5vw',fontSize:theme?.isPastBreak?'34px':'60px',textAlign:'left' }} />
                                    
                    <div style={{width:'100vw',zIndex:'10',margin:'auto', maxWidth:'90%',marginTop:'0px',marginBottom:theme?.isPastBreak?'48px':'5vw' }}>
                        
                        <Title style={{margin:'auto',zIndex:'10' ,fontWeight:'200',boxShadow:'0px 0px 0px grey',marginTop:'3vw',marginRight:'20vw',padding:'0px' ,textAlign:'left',textShadow:'0px 0px 50px white' }} data="">Need to purchase a service, but want to pay in installments? Dyvyup makes it easy. Simply accept a contract with one of our service providers, connect a card, and click pay. We handle the rest, the remaining payments will be automatically charged when due.
                        <br/>
                        <br/>
                        Interested in becoming a service provider? Find our more below.
                        </Title>   
                    </div> 
              </div>
            <img src={Iphone} alt='Logo Iphone with site on browser.'   style={{width:'20vw',margin:'auto',position:'relative',marginLeft:'auto',marginRight:'4vw' ,zIndex:'0',borderRadius:'3vw'}} />
        </div>
        <div style={{width:'100%',backgroundColor:'whitesmoke',justifyContent:'center',paddingBottom:theme?.isPastBreak?'45px':'5vw' ,paddingTop:theme?.isPastBreak?'45px':'5vw' ,alignItems:'center',display:'flex',flexFlow:'column'}}>
          
            <div style={{width:'90vw',display:'flex',flexDirection:'column',margin:'auto'}}> 
                <div style={{display:'flex',flexDirection:'row',margin:'auto',flexWrap:'wrap',justifyContent:'center',marginTop:'4vw' ,width:'100%' }}> 
                      <img src={secondImage} alt='Logo Image of financial payments.'   style={{width:theme?.isPastBreak?'50vw':'25vw',margin:'auto',marginTop:theme?.isPastBreak? "2vw":'auto',height:theme?.isPastBreak?'50vw':'25vw',borderRadius:'16px',boxShadow:'0px 0px 4px rgba(0,0,0,0.2)'}} />
                      <div style={{width:theme?.isPastBreak? '90vw':'45vw',flexFlow:'column',justifyContent:'start',height: 'auto',display:'flex',margin:'auto'  }} >
                          <Header data="How to purchase from a service."  style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0vw',marginTop:'8vw',fontSize:theme?.isPastBreak?'34px':'60px',textAlign:'left' }} />
                          <div style={{width:'100%',padding:'0px',boxShadow:'0px 0px 2px rgba(0,0,0,0.25)',backgroundColor:'whitesmoke',display:'flex',flexWrap:'wrap',minHeight:theme?.isPastBreak?'auto':'auto',margin:'auto',flexDirection:'column',marginTop:'1vw',marginBottom:'6vw',borderRadius:'12px'}}>
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Accept a contract.'}>
                                <HowItWorks  text='Your service provider will send a contract for you to accept.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Connect a card.'}>
                                <HowItWorks text='Connect your credit or debit card. Dont worry, we have you covered. We use industry standard cryptographic algorithms in addition to google security to keep you secure.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Pay off the contract in 4 increments.'}> 
                              <HowItWorks text='The remaining 3 payments will be paid off with the connected card and you will receive an email receipt.'/> 
                            </DropDownBox> 
                          </div> 
                      </div>  
                </div> 
            </div>
        </div>
        <div style={{width:'100%' ,backgroundColor:theme?.colors?.button ,display:'flex',flexWrap:'wrap',minHeight:'275px',margin:'auto',flexDirection:'column',marginTop:'0vw',marginBottom:'0vw',borderRadius:'0px'}}>
                    <Header data="Signup to buy from a buisiness now! " style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0px',fontSize:theme?.isPastBreak?'34px':'40px',textAlign:'center',color:theme?.colors?.secondary }} />
                            <Button click={(e)=>{
                              signupFunc()
                            }}   
                            
                            style={{  fontWeight:'900',backgroundColor:theme?.colors?.secondary,color:theme?.colors?.button,maxWidth:'auto',marginLeft:'auto',marginBottom:'auto',marginTop:'2vw'}}
                            name="Become a buyer" />
                </div>  
      <div style={{width:'100%',backgroundColor:'whitesmoke',justifyContent:'center',paddingBottom:theme?.isPastBreak?'45px':'5vw' ,paddingTop:theme?.isPastBreak?'45px':'5vw' ,alignItems:'center',display:'flex',flexFlow:'column'}}>              
          <div style={{width:'90vw',display:'flex',flexDirection:'column',margin:'auto'}}> 
                  <div style={{display:'flex',flexWrap:'wrap',margin:'auto',marginBottom:'4vw' ,justifyContent:'center',width:'100%' }}> 
                      <div style={{display:'flex',flexDirection:'row',margin:'auto',flexWrap:'wrap-reverse',justifyContent:'center',marginTop:'4vw' ,width:'100%'}}>
                          <div style={{width:theme?.isPastBreak? '90vw':'45vw',flexFlow:'column',justifyContent:'start',height: 'auto',display:'flex',margin:'auto'  }} >
                          <Header data="Why use DyvyUp for your service based buisiness?"  style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'4vw',marginTop:'8vw',fontSize:theme?.isPastBreak?'34px':'60px',textAlign:'left' }} />
                          <div style={{width:'100%' ,padding:'0px',boxShadow:'0px 0px 2px rgba(0,0,0,0.25)',backgroundColor:'whitesmoke',display:'flex',flexWrap:'wrap',minHeight:theme?.isPastBreak?'auto':'auto',margin:'auto',flexDirection:'column',marginTop:'1vw',marginBottom:'6vw',borderRadius:'12px'}}>
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Increased sales.'}>
                                <HowItWorks number='' text='Service providers who use dyvy up may notice an increase in average customer billables. Sign up and get more revenue today.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Easier payment collection.'}>
                                <HowItWorks number='' text='No need to keep recollecting payments. Sign a payment agreement and let us handle the rest.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Smaller service fees.'}>
                                <HowItWorks number='' text='Less than 1%? That is correct, with us you pay a fraction of the cost of our competitors.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'We share the late fees.'}>
                                <HowItWorks number='' text='Unlike others, we share our late fees. 50/50'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'How do I signup?'}>
                            <Button click={(e)=>{
                                    signupFunc(true)
                                  }}   
                                  style={{  fontWeight:'900',maxWidth:'auto',margin:'auto',marginTop:'20px'}}
                                  name="Become a Service Provider" />
                            </DropDownBox> 
                          </div>
                              
                          </div>  
                              <img src={thirdImage} alt='Logo Image of financial payments.'   style={{width:theme?.isPastBreak?'50vw':'25vw',margin:'auto',marginTop:theme?.isPastBreak? "2vw":'auto',height:theme?.isPastBreak?'50vw':'25vw',borderRadius:'16px',boxShadow:'0px 0px 4px rgba(0,0,0,0.2)'}} />                      
                      </div>
                  </div>
            </div>
        </div>
 
  
        

        <div style={{width:'100%' ,backgroundColor:'white' ,display:'flex',flexWrap:'wrap',minHeight:'275px',margin:'auto',flexDirection:'column',marginTop:'0vw',marginBottom:'0vw',borderRadius:'0px'}}>
    
            <Header data="Recieve payments for your service based business now. " style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0px',fontSize:theme?.isPastBreak?'34px':'40px',textAlign:'center' }} />
      
                  <Button click={(e)=>{
                    signupFunc(true)
                  }}   
                  style={{  fontWeight:'900',maxWidth:'auto',marginLeft:'auto' ,marginTop:'2vw'}}
                  name="Become a Service Provider" />
        </div> 
        <BottomBar/>
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setShowModal:(item)=>dispatch(setShowModal(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Home)