import React , {useState,useEffect} from 'react'
import {setShowMenu,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux';  
import Text from '../../../components/text/Text'
import Theme from '../../../components/theme/Theme'  
import ContractCard from '../../../components/contractcard/ContractCard';

const UpcomingPayments = (props) => {
    const [payments,setPayments] = useState([])
    const theme = Theme()
    const getUpcomingPayments = () =>{
        let localpayments = []
        //logic to go through contracts and find which ones will have payments due next...
        //add first, if and in list is less than add one, if less than and lis count == 3, 
        // add to list sort list, then slice to 3 elements from 4.
        localpayments = [...props?.data]
        localpayments = localpayments.filter(item => { return item.certified === true})
        localpayments.sort((doc1,doc2)=>{return doc1.lastpaid - doc2.lastpaid}) 
        localpayments = localpayments.splice(0,3)
        setPayments([...localpayments])
    }
    useEffect(()=>{
        getUpcomingPayments()
    },[props?.data])
    useEffect(()=>{

    },[payments])
    
  return (
    <div style={{width:'100%',height:'auto',display:'flex',flexDirection:'column',justifyContent:'center'}}>
    {/* <Title style={{ marginBottom:'5px'}} data={'Next 3 Payments'} /> */}
    <div style={{display:'flex',width:'90%',marginLeft:'0%',marginTop:'3%'}}>
    {theme?.isPastBreak? null:<>
    <Text style={{width:'150px',maxWidth:'150px',marginLeft:'5%',fontWeight:'900'}}>{props?.auth?.doc?.type === "provider"?"Customer": "Merchant"}</Text>
    {theme?.isPastBreak? null:<Text style={{width:'125px',marginLeft:'5%',fontWeight:'900'}}>Status</Text>}
    <Text style={{width:'125px',marginLeft:'5%',fontWeight:'900'}}>Due On</Text>
    <Text style={{width:'125px',marginLeft:'5%',fontWeight:'900'}}>Amount</Text>
    <Text style={{width:'125px',marginLeft:'5%',fontWeight:'900'}}>Total</Text>
    </>}
    </div>
        {payments?.map((item,index)=>{
            return <ContractCard resetUserData={props?.resetUserData} isUpcoming={true} index={index} item={item}/>
        })}
    </div> 
  )
}

 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(UpcomingPayments) 