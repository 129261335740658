import React,{useEffect} from 'react' 
import { connect } from 'react-redux';  
import classes from './Modal.module.css' 
import { AiFillCloseCircle } from 'react-icons/ai'; 
import {   setModalStack,setShowModal,setModalContent } from '../../actions';
import Theme from '../theme/Theme';
import { ArrowCircleLeftSharp } from '@mui/icons-material';

const dispatchToProps = dispatch =>{
    return {  
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),  
            setModalStack:()=>dispatch(setModalStack())
            }
} 
const Modal = (props) => {  
    const theme = Theme()
   
    useEffect(()=>{  
        if(props?.sys?.showModal){
            document.getElementById('root').style.overflow = 'hidden'
        } else {
            document.getElementById('root').style.overflow = 'auto'
        }
       
        return ()=>{
            document.getElementById('root').style.overflow = 'auto'
        }
    },[props?.sys?.showModal,props?.sys?.scroll ,props?.sys?.modalContent,props?.sys?.profileId,props.modalBackStack])
    
    return ( 
    <div  onClick={(e)=>{
        e.stopPropagation() 
        props?.setShowModal(false);props?.setModalContent(null);
    }}  className={classes.Modal}
        style={{ transform: (props?.sys?.showModal||props?.localShow) ?  props?.scroll ?'translateY(0vh)'  :'translateY(0vh)'  : 'translateY(100vh)',transitionDuration:'500ms',backgroundColor:'rgba(255,255,255,0.4)' ,webkitBackdropFilter:'blur(2px)', backdropFilter: 'blur(2px)',
        opacity:  (props?.sys?.showModal||props?.localShow) ? '1':'0',overflowX:'hidden'  ,height:props?.scroll ? '100%':'100%',width:'100%' ,position:'fixed',zIndex:'500'  
    }}> 
        <div onClick={(e)=>{
                e.stopPropagation()
            }} style={{ padding:'1px',overflowX:'hidden'  ,overflowY:'auto',display:'flex'  ,minHeight:theme.isPastBreak?'auto' :'160px'  ,paddingBottom:theme.isPastBreak?'5vh':'2vh',width:theme.isPastBreak?'100%':'45%',position: 'relative' ,  margin:'auto',marginTop:theme.isPastBreak?'auto':'auto' ,marginBottom:theme.isPastBreak?'0px':'auto',borderRadius:theme?.isPastBreak?'0px':'12px' ,minWidth:theme.isPastBreak?'auto':'450px' ,zIndex:'500',boxShadow:'0px 0px 10px '+theme.colors.main  ,backgroundColor:"rgba(255,255,255,0.95)"  }}> 
            {props?.modalBackStack.length > 0 ?<ArrowCircleLeftSharp style={{cursor:'pointer',top:theme.isPastBreak?'1.5%':'0.5%',right:theme.isPastBreak?"6.8%":"6.5%" ,color:theme?.colors.button,fontSize:'32px',zIndex:'4500',borderRadius:'6px',position:'absolute'  }} onClick={()=>{props?.setModalStack()}}/> :null}
            <AiFillCloseCircle style={{cursor:'pointer',top:theme.isPastBreak?'1.5%':'0.5%',right:theme.isPastBreak?"0.8%":"0.5%" ,color:theme?.colors.button,fontSize:'32px',zIndex:'4500',borderRadius:'6px',position:'absolute'  }} onClick={()=>{props?.setShowModal(false);props?.setModalContent(null);}}/> 
            {props?.sys?.modalContent}   
        </div>
    </div> 
    )
}

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Modal)
