import React,{useState,useEffect} from 'react'
import { AiFillCaretRight } from 'react-icons/ai'
import Header from '../header/Header'
import Theme from '../theme/Theme';
const DropDownBox = (props) => {
    const theme = Theme()
    const [minHeight,setMinHeight] = useState('0vh')
    const [maxHeight,setMaxHeight] = useState('0vh')
    const [show,setShow] = useState(false)
    useEffect(()=>{
        if(show){
            setMinHeight(props.minHeight)
            setMaxHeight('auto')
        } else {
            setMinHeight('0vh')
            setMaxHeight("0vh")
        }

    },[show]) 
   
    return (   <div style={{width: 'auto',height:'auto',justifyContent:'center',display:"flex",flexFlow:'column',margin:'8px',alignItems:'center',backgroundColor:'whitesmoke'}}>
       <Header onClick={()=>{setShow(!show)}}   style={ {marginLeft:'0px',fontSize:theme?.isPastBreak?'20px':'24px',fontWeight:'100',cursor:'pointer',borderBottom:'2px solid grey',marginBottom:'auto',width:'100%',textAlign:'start' ,justifyContent:'flex-start',alignItems:'center',display:'flex',color:theme.colors.mainDark}} > <AiFillCaretRight onClick={()=>{setShow(!show)}}  style={{marginRight:"5px",color:theme.colors.button,transform:show ? 'rotate(90deg)':'rotate(0deg)',transition:'all 300ms ease-in-out',width:'30px',fontSize:'22px',marginLeft:'0px' }}  /> {props.name}</Header>
       <div style={{width:'100%',overflow:'hidden',overflowY:show ? 'auto':'hidden',transition:'all 300ms ease-in-out' ,display:'flex' ,marginTop:'0vh',marginBottom:'0vh',minHeight:show?'1vh':'0px',maxHeight:show?'50vh':'0px',justifyContent:'center',flexWrap:'wrap',alignItems:'center'}}>
            {props.children} 
       </div>
      </div>
    )
}

export default DropDownBox
