import React,{useEffect} from 'react'
import Theme from '../theme/Theme'
import useWindowDimensions from '../theme/useWindowDimensions'
import Header from '../header/Header'
import Title from '../title/Title'
const HowItWorks = (props) => {
    const {height,width} = useWindowDimensions()
    const theme = Theme()
    useEffect(()=>{

    },[width])
    return (
        <div style={{display:'flex',flexFlow:'row',alignItems:'flexStart',margin:'auto',padding:'15px',width:width <theme?.isPastBreak ? '95%':'95%'}}>
                <Header style={{color:'rgba(0,0,0,0.3)',fontSize:'16px',fontWeight:'400',textShadow:'0px 0px 0px grey',padding:'5px',textAlign:'left' }}>{props.number}</Header><Title style={{fontWeight:'300',color:'black',fontSize:'16px',textAlign:'left' }}>{props.text}</Title>
        </div>
    )
}

export default HowItWorks
